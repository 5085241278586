<template>
  <main>
    <div class="banner">
      <img v-lazy="require('./dydl_img/banner.webp')" alt="" class="bannerr" />
      <span class="dydl">导游导览</span>
      <span class="fh1"
        >专有内容的在线电子导览，包括旅游景区的手绘地图，以及旅游景区内景点的文字介绍及语音讲解，并且加入实时导航等功能</span
      >
    </div>
    <div class="bg2">
      <span class="fays">方案优势</span>
      <div class="box1">
        <img src="./dydl_img/icon1.png" alt="" class="icon1" />
        <span class="xz1">便利性</span>
        <span class="fh2"
          >游客通过手机一键自主收听导游讲解，无须等待，无须借助其他设备，方便，灵活。</span
        >
      </div>
      <div class="box2">
        <img src="./dydl_img/icon2.png" alt="" class="icon2" />
        <span class="xz2">丰富性</span>
        <span class="fh3"
          >除收听同步语音，还可以浏览相应文字、图片等多媒体资料。
        </span>
      </div>
      <div class="box3">
        <img src="./dydl_img/icon3.png" alt="" class="icon3" />
        <span class="xz3">交互性</span>
        <span class="fh4">人机交互良好，活泼、联动性好。</span>
      </div>
      <div class="box4">
        <img src="./dydl_img/icon4.png" alt="" class="icon4" />
        <span class="xz4">拓展性</span>
        <span class="fh5"
          >可与数字博物馆、数字景区、智慧旅游、微信、网站等结合，提升数字化只能化服务水平。</span
        >
      </div>
      <div class="box5">
        <img src="./dydl_img/icon5.png" alt="" class="icon5" />
        <span class="xz5">智能性</span>
        <span class="fh6"
          >一键开启自动化语音，不同位置、不同景点对应不同讲解，精确智能识别。智能同步，省时省力。</span
        >
      </div>
      <div class="box6">
        <img src="./dydl_img/icon6.png" alt="" class="icon6" />
        <span class="xz6">易用性</span>
        <span class="fh7"
          >管理方便，所有内容可增删除修改。使用方便，播放界面简洁美观。</span
        >
      </div>
    </div>
    <div class="bg3">
      <span class="h1">智慧导游导览产品</span>
      <div class="img11">
        <img v-lazy="require('./dydl_img/img1.webp')" class="img1" />
      </div>
      <div class="img22">
        <img v-lazy="require('./dydl_img/img2.webp')" class="img2" />
      </div>
    </div>
    <div class="bg4">
      <div class="img33">
        <img v-lazy="require('./dydl_img/img3.webp')" class="img3" />
      </div>
      <div class="img44">
        <img v-lazy="require('./dydl_img/img4.webp')" class="img4" />
      </div>
      <div class="img55">
        <img v-lazy="require('./dydl_img/img5.webp')" class="img5" />
      </div>
    </div>
    <Header></Header>
    <Footer></Footer>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0 !important;
  padding: 0 !important;
}
.banner {
  width: 100%;
  height: 590px;
  left: 0;
  top: 0;
  // background: url(./imgs/bg1.png);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img[lazy="loading"] {
    width: 200px;
    height: 200px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    height: 590px;
    width: 100%;
  }
  .bannerr {
    height: 590px;
    width: 100%;
  }
  .dydl {
    min-width: 206px;
    height: 67px;
    font-size: 48px;
    font-weight: 500;
    color: #ffffff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 230px;
    left: 361px;
    text-align: left;
  }
  .fh1 {
    width: 706px;
    height: 66px;
    font-size: 24px;
    font-weight: 400;
    color: #ffffff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 321px;
    left: 361px;
    text-align: left;
  }
}
.bg2 {
  height: 806px;
  position: relative;
  .fays {
    min-width: 165px;
    height: 56px;
    font-size: 40px;
    font-weight: 500;
    color: #333333;
    position: absolute;
    text-align: left;
    top: 80px;
    left: 878px;
  }
  .box1 {
    min-width: 585px;
    height: 150px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 6px 6px;
    position: absolute;
    top: 196px;
    left: 360px;
    .icon1 {
      min-width: 64px;
      height: 64px;
      position: absolute;
      left: 48px;
      top: 43px;
    }
    .xz1 {
      min-width: 63px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 43px;
      left: 136px;
    }
    .fh2 {
      width: 395px;
      height: 40px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 87px;
      left: 136px;
      text-align: left;
    }
  }
  .box2 {
    min-width: 585px;
    height: 150px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 6px 6px;
    position: absolute;
    top: 196px;
    left: 975px;
    .icon2 {
      min-width: 64px;
      height: 64px;
      position: absolute;
      left: 48px;
      top: 43px;
    }
    .xz2 {
      min-width: 63px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 43px;
      left: 136px;
    }
    .fh3 {
      width: 395px;
      height: 40px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 87px;
      left: 136px;
      text-align: left;
    }
  }
  .box3 {
    min-width: 585px;
    height: 150px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 6px 6px;
    position: absolute;
    top: 376px;
    left: 360px;
    .icon3 {
      min-width: 64px;
      height: 64px;
      position: absolute;
      left: 48px;
      top: 43px;
    }
    .xz3 {
      min-width: 63px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 43px;
      left: 136px;
    }
    .fh4 {
      width: 395px;
      height: 40px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 87px;
      left: 136px;
      text-align: left;
    }
  }
  .box4 {
    min-width: 585px;
    height: 150px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 6px 6px;
    position: absolute;
    top: 376px;
    left: 975px;
    .icon4 {
      min-width: 64px;
      height: 64px;
      position: absolute;
      left: 48px;
      top: 43px;
    }
    .xz4 {
      min-width: 63px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 43px;
      left: 136px;
    }
    .fh5 {
      width: 395px;
      height: 40px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 87px;
      left: 136px;
      text-align: left;
    }
  }
  .box5 {
    min-width: 585px;
    height: 150px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 6px 6px;
    position: absolute;
    top: 556px;
    left: 360px;
    .icon5 {
      min-width: 64px;
      height: 64px;
      position: absolute;
      left: 48px;
      top: 43px;
    }
    .xz5 {
      min-width: 63px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 43px;
      left: 136px;
    }
    .fh6 {
      width: 395px;
      height: 40px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 87px;
      left: 136px;
      text-align: left;
    }
  }
  .box6 {
    min-width: 585px;
    height: 150px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 0px 0px 6px 6px;
    position: absolute;
    top: 556px;
    left: 975px;
    .icon6 {
      min-width: 64px;
      height: 64px;
      position: absolute;
      left: 48px;
      top: 43px;
    }
    .xz6 {
      min-width: 63px;
      height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      position: absolute;
      top: 43px;
      left: 136px;
    }
    .fh7 {
      width: 395px;
      height: 40px;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      position: absolute;
      top: 87px;
      left: 136px;
      text-align: left;
    }
  }
}
.bg3 {
  height: 1007px;
  background: #f6f8fb;
  position: relative;
  .h1 {
    min-width: 330px;
    height: 56px;
    font-size: 40px;
    font-weight: 500;
    color: #333333;
    position: absolute;
    text-align: left;
    top: 80px;
    left: 796px;
  }
  .img11 {
    width: 1200px;
    height: 357px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 196px;
    left: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 357px;
      width: 100%;
    }
    .img1 {
      height: 357px;
    }
  }
  .img22 {
    width: 1200px;
    height: 324px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 583px;
    left: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height: 324px;
      width: 100%;
    }
    .img2 {
      height: 324px;
    }
  }
}
.bg4 {
  height: 675px;
  position: relative;
  .img33 {
    width: 380px;
    height: 379px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 196px;
    left: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height:  379px;
      width: 100%;
    }
    .img3 {
      width: 380px;
      height: 379px;
    }
  }
  .img44 {
    width: 380px;
    height: 379px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 196px;
    left: 770px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height:  379px;
      width: 100%;
    }
    .img4 {
     width: 380px;
      height: 379px;
    }
  }
  .img55 {
    width: 380px;
    height: 379px;
    background: #ffffff;
    box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    position: absolute;
    top: 196px;
    left: 1180px;
    display: flex;
    align-items: center;
    justify-content: center;
    img[lazy="loading"] {
      width: 200px;
      height: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      height:  379px;
      width: 100%;
    }
    .img4 {
      width: 380px;
      height: 379px;
    }
  }
}
</style>
